<template>
   <v-layout aling-start>
      <v-flex>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <v-container fluid>
            <v-layout
               wrap
               :style="
                  $vuetify.breakpoint.xlOnly
                     ? 'padding-right: 10%; padding-left: 10%'
                     : ''
               "
            >
               <v-flex
                  v-if="solicitudInfo != null"
                  class="font-weight-medium text-h5"
                  xs12
                  text-center
                  mb-4
               >
                  Su número de referencia es: <br />
                  <span class="colorPrimary font-weight-bold">{{
                     solicitudInfo.numeroReferencia
                  }}</span>
               </v-flex>

               <v-flex xs12>
                  <v-stepper
                     v-model="stepNum"
                     non-linear
                     :class="$vuetify.breakpoint.xsOnly ? 'elevation-0' : ''"
                  >
                     <v-stepper-header v-if="false">
                        <v-stepper-step :complete="stepNum > 1" step="1">
                           Tipo de beneficio de beca
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="stepNum > 2" step="2">
                           Tipo de beneficiario
                        </v-stepper-step>

                        <v-divider></v-divider>z

                        <v-stepper-step :complete="stepNum > 2.1" step="2.1">
                           Verificar requisitos
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="stepNum > 3" step="3">
                           Datos generales
                        </v-stepper-step>
                     </v-stepper-header>

                     <v-stepper-items
                        :class="$vuetify.breakpoint.smAndUp ? 'px-8' : 'py-2'"
                     >
                        <!-- PASO 1 -->
                        <v-stepper-content
                           step="1"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <v-layout wrap>
                              <v-flex
                                 v-if="
                                    oidcUser
                                       ? oidcUser.nivelasesor == 0
                                          ? true
                                          : false
                                       : false
                                 "
                                 xs12
                                 text-center
                              >
                                 <span class="font-weight-medium text-h5">
                                    Preinscripción de beca parcial
                                 </span>
                                 <br />
                                 <br />
                              </v-flex>

                              <v-layout
                                 v-if="
                                    oidcUser
                                       ? oidcUser.nivelasesor == 0
                                          ? true
                                          : false
                                       : false
                                 "
                                 wrap
                              >
                                 <v-flex xs12>
                                    <span
                                       class="font-weight-medium"
                                       style="font-size: 20px"
                                    >
                                       Seleccione el tipo de beneficio a
                                       aplicar:
                                    </span>
                                 </v-flex>
                                 <v-flex xs12>
                                    <v-card elevation="0">
                                       <v-card-text
                                          class="text--primary ml-0 pl-0"
                                       >
                                          <v-container
                                             class="px-1"
                                             grid-list-md
                                             fluid
                                          >
                                             <v-layout wrap>
                                                <!-- SERVICIOS ESTUDIANTILES -->
                                                <v-flex xs12 sm6 md4>
                                                   <v-layout
                                                      align-center
                                                      justify-center
                                                      wrap
                                                   >
                                                      <!-- BOTON -->
                                                      <v-flex
                                                         xs12
                                                         :class="
                                                            $vuetify.breakpoint
                                                               .smAndUp
                                                               ? 'px-16'
                                                               : ''
                                                         "
                                                      >
                                                         <v-btn
                                                            rounded
                                                            color="primary"
                                                            width="110%"
                                                            height="60px"
                                                            x-large
                                                            @click="
                                                               chooseServEstudiantiles
                                                            "
                                                            >Servicios
                                                            estudiantiles
                                                         </v-btn>
                                                      </v-flex>
                                                      <!-- MENSAJE -->
                                                      <v-flex
                                                         xs12
                                                         class="text-center mt-4"
                                                      >
                                                         <div
                                                            class="text-h6"
                                                            style="
                                                               white-space: pre-wrap;
                                                            "
                                                         >
                                                            Tipos de
                                                            beneficiarios que
                                                            aplican:
                                                         </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                         <v-card
                                                            class="mx-auto font-weight-medium"
                                                            width="300px"
                                                            flat
                                                         >
                                                            <v-list-item
                                                               two-line
                                                            >
                                                               <v-icon
                                                                  left
                                                                  class="colorPrimary"
                                                                  style="
                                                                     font-size: 10px;
                                                                  "
                                                                  >fas
                                                                  fa-circle</v-icon
                                                               >
                                                               <v-list-item-content>
                                                                  <v-list-item-title>
                                                                     Estudiante
                                                                     activo
                                                                     pregrado
                                                                  </v-list-item-title>
                                                               </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item
                                                               two-line
                                                            >
                                                               <v-icon
                                                                  left
                                                                  class="colorPrimary"
                                                                  style="
                                                                     font-size: 10px;
                                                                  "
                                                                  >fas
                                                                  fa-circle</v-icon
                                                               >
                                                               <v-list-item-content>
                                                                  <v-list-item-title>
                                                                     Estudiante
                                                                     activo
                                                                     postgrado
                                                                  </v-list-item-title>
                                                               </v-list-item-content>
                                                            </v-list-item>
                                                         </v-card>
                                                      </v-flex>
                                                   </v-layout>
                                                </v-flex>

                                                <!-- DIVISORES -->
                                                <v-divider
                                                   v-if="
                                                      $vuetify.breakpoint
                                                         .smAndUp
                                                   "
                                                   vertical
                                                ></v-divider>
                                                <v-divider
                                                   v-if="
                                                      $vuetify.breakpoint.xsOnly
                                                   "
                                                   class="my-8 mx-8"
                                                ></v-divider>

                                                <!-- ADMINISTRATIVA-->
                                                <v-flex xs12 sm6 md4>
                                                   <v-layout
                                                      align-center
                                                      fill-height
                                                      justify-center
                                                      wrap
                                                   >
                                                      <!-- BOTON -->
                                                      <v-flex
                                                         xs12
                                                         :class="
                                                            $vuetify.breakpoint
                                                               .smAndUp
                                                               ? 'px-16'
                                                               : ''
                                                         "
                                                      >
                                                         <v-btn
                                                            rounded
                                                            color="primary"
                                                            width="110%"
                                                            height="60px"
                                                            x-large
                                                            @click="
                                                               chooseAdministrativa
                                                            "
                                                            >Administrativo
                                                         </v-btn>
                                                      </v-flex>
                                                      <!-- MENSAJE -->
                                                      <v-flex
                                                         xs12
                                                         class="text-center mt-4"
                                                      >
                                                         <div
                                                            class="text-h6"
                                                            style="
                                                               white-space: pre-wrap;
                                                            "
                                                         >
                                                            Tipos de
                                                            beneficiarios que
                                                            aplican:
                                                         </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                         <v-card
                                                            class="mx-auto font-weight-medium"
                                                            width="300px"
                                                            flat
                                                         >
                                                            <v-list-item>
                                                               <v-icon
                                                                  left
                                                                  class="colorPrimary"
                                                                  style="
                                                                     font-size: 10px;
                                                                  "
                                                                  >fas
                                                                  fa-circle</v-icon
                                                               >
                                                               <v-list-item-content>
                                                                  <v-list-item-title>
                                                                     Empleados
                                                                     UNICAES
                                                                  </v-list-item-title>
                                                               </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item
                                                               two-line
                                                            >
                                                               <v-icon
                                                                  left
                                                                  class="colorPrimary"
                                                                  style="
                                                                     font-size: 10px;
                                                                  "
                                                                  >fas
                                                                  fa-circle</v-icon
                                                               >
                                                               <v-list-item-content>
                                                                  <v-list-item-title>
                                                                     Hijos de
                                                                     empleados
                                                                  </v-list-item-title>

                                                                  <v-list-item-subtitle>
                                                                     Empleados a
                                                                     tiempo
                                                                     completo
                                                                  </v-list-item-subtitle>
                                                               </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item>
                                                               <v-icon
                                                                  left
                                                                  class="colorPrimary"
                                                                  style="
                                                                     font-size: 10px;
                                                                  "
                                                                  >fas
                                                                  fa-circle</v-icon
                                                               >
                                                               <v-list-item-content>
                                                                  <v-list-item-title>
                                                                     Sacerdotes
                                                                  </v-list-item-title>
                                                               </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item>
                                                               <v-icon
                                                                  left
                                                                  class="colorPrimary"
                                                                  style="
                                                                     font-size: 10px;
                                                                  "
                                                                  >fas
                                                                  fa-circle</v-icon
                                                               >
                                                               <v-list-item-content>
                                                                  <v-list-item-title>
                                                                     Religiosos
                                                                  </v-list-item-title>
                                                               </v-list-item-content>
                                                            </v-list-item>
                                                         </v-card>
                                                      </v-flex>
                                                   </v-layout>
                                                </v-flex>

                                                <!-- DIVISORES -->
                                                <v-divider
                                                   v-if="
                                                      $vuetify.breakpoint
                                                         .smAndUp
                                                   "
                                                   vertical
                                                ></v-divider>
                                                <v-divider
                                                   v-if="
                                                      $vuetify.breakpoint.xsOnly
                                                   "
                                                   class="my-8 mx-8"
                                                ></v-divider>

                                                <!-- NUEVO TIPO DE BECA -->
                                                <v-flex xs12 sm6 md4>
                                                   <v-layout
                                                      align-center
                                                      justify-center
                                                      wrap
                                                   >
                                                      <!-- BOTON -->
                                                      <v-flex
                                                         xs12
                                                         :class="
                                                            $vuetify.breakpoint
                                                               .smAndUp
                                                               ? 'px-16'
                                                               : ''
                                                         "
                                                      >
                                                         <v-btn
                                                            rounded
                                                            color="primary"
                                                            width="110%"
                                                            height="60px"
                                                            x-large
                                                            @click="
                                                               chooseAuspiciada
                                                            "
                                                            >Auspiciado
                                                         </v-btn>
                                                      </v-flex>

                                                      <!-- MENSAJE -->
                                                      <v-flex
                                                         xs12
                                                         class="text-center mt-4"
                                                      >
                                                         <div
                                                            class="text-h6"
                                                            style="
                                                               white-space: pre-wrap;
                                                            "
                                                         >
                                                            Tipos de
                                                            beneficiarios que
                                                            aplican:
                                                         </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                         <v-card
                                                            class="mx-auto font-weight-medium"
                                                            width="300px"
                                                            flat
                                                         >
                                                            <v-list-item
                                                               two-line
                                                            >
                                                               <v-icon
                                                                  left
                                                                  class="colorPrimary"
                                                                  style="
                                                                     font-size: 10px;
                                                                  "
                                                                  >fas
                                                                  fa-circle</v-icon
                                                               >
                                                               <v-list-item-content>
                                                                  <v-list-item-title>
                                                                     Estudiante
                                                                     activo
                                                                     pregrado
                                                                  </v-list-item-title>
                                                               </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item
                                                               two-line
                                                            >
                                                               <v-icon
                                                                  left
                                                                  class="colorPrimary"
                                                                  style="
                                                                     font-size: 10px;
                                                                  "
                                                                  >fas
                                                                  fa-circle</v-icon
                                                               >
                                                               <v-list-item-content>
                                                                  <v-list-item-title>
                                                                     Estudiante
                                                                     activo
                                                                     postgrado
                                                                  </v-list-item-title>
                                                               </v-list-item-content>
                                                            </v-list-item>
                                                         </v-card>
                                                      </v-flex>
                                                   </v-layout>
                                                </v-flex>

                                                <!-- ADJUNTAR LISTADO -->
                                                <v-flex xs12>
                                                   <v-divider
                                                      class="my-8"
                                                   ></v-divider>
                                                   <ReaplicacionBecaAdjunto />
                                                </v-flex>
                                             </v-layout>
                                          </v-container>
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-layout>
                        </v-stepper-content>

                        <!-- PASO 2 -->
                        <v-stepper-content
                           step="2"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <v-layout wrap>
                              <v-flex xs12>
                                 <span class="font-weight-medium text-h5"
                                    >Tipo de beneficiario
                                 </span>
                              </v-flex>
                              <v-flex xs12 sm6 class="d-flex align-center py-4">
                                 <div
                                    class="font-weight-medium"
                                    style="font-size: 20px"
                                 >
                                    Seleccione el tipo de beneficiario:
                                 </div>
                              </v-flex>

                              <v-flex xs12 sm6>
                                 <v-form
                                    ref="formBeneficiario"
                                    v-model="validBeneficiario"
                                    lazy-validation
                                 >
                                    <v-autocomplete
                                       v-model="selectBeneficiario"
                                       :items="filteredBeneficiarios"
                                       :rules="rulesBeneficiario"
                                       color="primary"
                                       item-text="nombre"
                                       item-value="beneficiarioId"
                                       no-data-text="Sin resultados..."
                                       label="Tipo de beneficiario"
                                    ></v-autocomplete>
                                 </v-form>
                              </v-flex>
                              <v-flex
                                 v-if="tipoBeneficioId === 2"
                                 mt-4
                                 d-flex
                                 justify-center
                              >
                                 <v-checkbox
                                    v-model="bienestarUniversitario"
                                    label="Bienestar Universitario"
                                    color="primary"
                                 >
                                 </v-checkbox>
                              </v-flex>
                              <v-flex
                                 v-if="
                                    selectBeneficiario == 1 ||
                                    selectBeneficiario == 2 ||
                                    selectBeneficiario == 4 ||
                                    selectBeneficiario == 5 ||
                                    selectBeneficiario == 6 ||
                                    selectBeneficiario == 3 ||
                                    selectBeneficiario == 7 ||
                                    selectBeneficiario == 8 ||
                                    selectBeneficiario == 9
                                 "
                                 xs12
                                 class="font-weight-medium mt-8 text-center"
                                 style="font-size: 18px"
                              >
                                 <v-divider class="mb-8"></v-divider>
                                 <div style="mx-auto max-width: 500px">
                                    Para completar la solicitud de beca bajo
                                    calidad de
                                    <span class="colorPrimary">{{
                                       selectBeneficiarioNombre.toLowerCase()
                                    }}</span
                                    >, necesitará suministrar los siguientes
                                    comprobantes:
                                 </div>
                              </v-flex>
                              <!-- LISTAS DE COMPROBANTES A SOLICITAR -->
                              <v-flex
                                 v-if="
                                    selectBeneficiario == 1 ||
                                    selectBeneficiario == 2 ||
                                    selectBeneficiario == 4 ||
                                    selectBeneficiario == 5 ||
                                    selectBeneficiario == 6 ||
                                    selectBeneficiario == 3 ||
                                    selectBeneficiario == 7 ||
                                    selectBeneficiario == 8 ||
                                    selectBeneficiario == 9
                                 "
                                 xs12
                                 my-4
                              >
                                 <v-card
                                    class="mx-auto font-weight-medium"
                                    width="320px"
                                    flat
                                 >
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 1 ||
                                          selectBeneficiario == 2 ||
                                          selectBeneficiario == 3 ||
                                          selectBeneficiario == 8 ||
                                          selectBeneficiario == 9
                                       "
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Declaración jurada
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 1 ||
                                          selectBeneficiario == 2 ||
                                          selectBeneficiario == 8 ||
                                          selectBeneficiario == 9
                                       "
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Boleta de notas
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 1 ||
                                          selectBeneficiario == 2 ||
                                          selectBeneficiario == 8 ||
                                          selectBeneficiario == 9
                                       "
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Referencia académica 1
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 1 ||
                                          selectBeneficiario == 8
                                       "
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Referencia académica 2
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 1 ||
                                          selectBeneficiario == 2 ||
                                          selectBeneficiario == 4 ||
                                          selectBeneficiario == 8 ||
                                          selectBeneficiario == 9
                                       "
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Carencia de bienes (CNR)
                                          </v-list-item-title>
                                          <v-list-item-subtitle
                                             v-if="
                                                selectBeneficiario == 1 ||
                                                selectBeneficiario == 8
                                             "
                                             class="text-wrap"
                                          >
                                             Solicitar a nombre del padre, madre
                                             o responsable del estudiante
                                          </v-list-item-subtitle>
                                          <v-list-item-subtitle
                                             v-if="
                                                selectBeneficiario == 2 ||
                                                selectBeneficiario == 9
                                             "
                                             class="text-wrap"
                                          >
                                             Solicitar a nombre de la persona
                                             que costea los estudios de maestría
                                          </v-list-item-subtitle>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 1 ||
                                          selectBeneficiario == 2 ||
                                          selectBeneficiario == 4 ||
                                          selectBeneficiario == 8 ||
                                          selectBeneficiario == 9
                                       "
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Tarjeta de vehículo
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                             En caso de poseer uno
                                          </v-list-item-subtitle>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 1 ||
                                          selectBeneficiario == 2 ||
                                          selectBeneficiario == 4 ||
                                          selectBeneficiario == 8 ||
                                          selectBeneficiario == 9
                                       "
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Remesas
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 1 ||
                                          selectBeneficiario == 2 ||
                                          selectBeneficiario == 4 ||
                                          selectBeneficiario == 8 ||
                                          selectBeneficiario == 9
                                       "
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Constancias de sueldo
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 1 ||
                                          selectBeneficiario == 2 ||
                                          selectBeneficiario == 4 ||
                                          selectBeneficiario == 8 ||
                                          selectBeneficiario == 9
                                       "
                                       two-line
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Recibos de servicios
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                             Agua, luz, internet, etc.
                                          </v-list-item-subtitle>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 1 ||
                                          selectBeneficiario == 2 ||
                                          selectBeneficiario == 4 ||
                                          selectBeneficiario == 8 ||
                                          selectBeneficiario == 9
                                       "
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Recibos de pago de casa o alquiler
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="selectBeneficiario == 4">
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Carta de empleador
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="selectBeneficiario == 6">
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Carta de congregación
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="selectBeneficiario == 5">
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Visto bueno de Obispo
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                       v-if="
                                          selectBeneficiario == 3 ||
                                          selectBeneficiario == 7
                                       "
                                    >
                                       <v-icon
                                          left
                                          class="colorPrimary"
                                          style="font-size: 10px"
                                          >fas fa-circle</v-icon
                                       >
                                       <v-list-item-content>
                                          <v-list-item-title>
                                             Boleta de planilla
                                          </v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                 </v-card>
                              </v-flex>

                              <v-flex
                                 xs12
                                 my-4
                                 :class="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'd-flex justify-center'
                                       : 'd-flex flex-column-reverse justify-center'
                                 "
                              >
                                 <v-btn
                                    :class="
                                       $vuetify.breakpoint.smAndUp ? 'mr-2' : ''
                                    "
                                    large
                                    rounded
                                    :block="$vuetify.breakpoint.xsOnly"
                                    color="grey lighten-3"
                                    @click="cancelarBeneficiarios"
                                 >
                                    Cancelar
                                 </v-btn>
                                 <!-- BOTON CMBBENEFICIARIOS | CONTINUAR -->
                                 <v-btn
                                    :class="
                                       $vuetify.breakpoint.smAndUp ? '' : 'mb-3'
                                    "
                                    color="primary"
                                    large
                                    rounded
                                    :block="$vuetify.breakpoint.xsOnly"
                                    :disabled="!validBeneficiario"
                                    @click="nextStepBeneficiarios"
                                 >
                                    Continuar
                                 </v-btn>
                              </v-flex>
                           </v-layout>
                        </v-stepper-content>

                        <!-- PASO 2.1 -->
                        <v-stepper-content
                           step="2.1"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <v-layout wrap>
                              <v-flex xs12 mb-4>
                                 <span class="font-weight-medium text-h5"
                                    >Verificar requisitos
                                 </span>
                              </v-flex>

                              <v-flex
                                 xs12
                                 class="font-weight-medium text-subtitle-1 pb-8"
                              >
                                 <v-card class="borderDetallesPrimary">
                                    <v-layout
                                       wrap
                                       fill-height
                                       align-center
                                       :class="
                                          $vuetify.breakpoint.smAndUp
                                             ? 'px-6'
                                             : 'px-4 py-4'
                                       "
                                    >
                                       <v-flex xs12 sm1 text-center>
                                          <v-icon size="35" color="primary">
                                             fa-info-circle
                                          </v-icon>
                                       </v-flex>
                                       <v-flex
                                          xs12
                                          sm11
                                          text-justify
                                          class="pt-2"
                                       >
                                          <p
                                             class="font-weight-medium"
                                             style="margin-bottom: 10px"
                                          >
                                             Para poder solicitar una beca bajo
                                             calidad de alumno activo, debe
                                             cumplir ciertos requisitos. Los
                                             cuales puede encontrar detallados
                                             en el
                                             <a
                                                href="https://www.catolica.edu.sv/#:~:text=Anuario,Acad%C3%A9mico"
                                                target="_blank"
                                                style="text-decoration: none"
                                                class="colorPrimary"
                                                >anuario académico</a
                                             >
                                             del presente año, en la sección de
                                             REGLAMENTO DE BECAS, CAPÍTULO II.
                                          </p>
                                       </v-flex>
                                    </v-layout>
                                 </v-card>
                              </v-flex>

                              <v-flex xs12 sm4 class="d-flex align-center">
                                 <div
                                    class="font-weight-medium"
                                    style="font-size: 20px"
                                 >
                                    Ingrese el número de carnet:
                                 </div>
                              </v-flex>
                              <v-flex xs12 sm8>
                                 <v-form
                                    ref="formCarnet"
                                    v-model="validCarnet"
                                    lazy-validation
                                 >
                                    <v-text-field
                                       v-model="carnet"
                                       v-mask="'####-AA-###'"
                                       :rules="rulesCarnet"
                                       color="primary"
                                       label="Carnet"
                                    >
                                    </v-text-field>
                                 </v-form>
                              </v-flex>

                              <v-flex
                                 v-if="verificandoRequisitos"
                                 my-8
                                 xs12
                                 d-flex
                                 justify-center
                                 align-center
                              >
                                 <span
                                    class="colorPrimary font-weight-medium mr-2"
                                    style="font-size: 24px"
                                 >
                                    Verificando...
                                 </span>
                                 <i
                                    class="ml-2 fas fa-sync-alt fa-3x fa-spin colorPrimary"
                                    data-fa-transform="down-5  right-5"
                                 ></i>
                              </v-flex>

                              <v-flex
                                 v-if="pasoRequisitos != null"
                                 :my-8="pasoRequisitos"
                                 :mt-8="!pasoRequisitos"
                                 xs12
                                 d-flex
                                 justify-center
                                 align-center
                              >
                                 <p
                                    class="colorPrimary font-weight-medium mr-2 text-justify"
                                    :style="
                                       $vuetify.breakpoint.smAndUp
                                          ? 'font-size: 18px; width: 500px; white-space: pre-line;'
                                          : 'font-size: 18px; white-space: pre-line;'
                                    "
                                 >
                                    {{
                                       pasoRequisitos
                                          ? 'Buenas noticias, el alumno cumple con todos los requisitos para aplicar a una beca! Por favor haga clic en el botón continuar.'
                                          : generarMensajeRequisitos()
                                    }}
                                 </p>
                              </v-flex>

                              <v-flex
                                 v-if="pasoRequisitos == false"
                                 mt-4
                                 d-flex
                                 justify-center
                              >
                                 <v-checkbox
                                    v-model="checkContinuar"
                                    label="Deseo continuar"
                                    color="primary"
                                 >
                                 </v-checkbox>
                              </v-flex>

                              <v-flex
                                 xs12
                                 my-4
                                 :class="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'd-flex justify-center'
                                       : 'd-flex flex-column-reverse justify-center'
                                 "
                              >
                                 <v-btn
                                    :class="
                                       $vuetify.breakpoint.smAndUp ? 'mr-2' : ''
                                    "
                                    large
                                    rounded
                                    :block="$vuetify.breakpoint.xsOnly"
                                    color="grey lighten-3"
                                    @click="cancelarValidarRequisitos"
                                 >
                                    Cancelar
                                 </v-btn>
                                 <v-btn
                                    v-if="!pasoRequisitos && !checkContinuar"
                                    :class="
                                       $vuetify.breakpoint.smAndUp ? '' : 'mb-3'
                                    "
                                    color="primary"
                                    large
                                    rounded
                                    :block="$vuetify.breakpoint.xsOnly"
                                    :disabled="
                                       !validCarnet || verificandoRequisitos
                                    "
                                    @click="validarRequisitos"
                                 >
                                    Verificar requisitos
                                 </v-btn>

                                 <v-btn
                                    v-if="pasoRequisitos || checkContinuar"
                                    :class="
                                       $vuetify.breakpoint.smAndUp ? '' : 'mb-3'
                                    "
                                    color="primary"
                                    large
                                    rounded
                                    :block="$vuetify.breakpoint.xsOnly"
                                    :disabled="
                                       !pasoRequisitos && !checkContinuar
                                    "
                                    @click="nextStepRequisitos"
                                 >
                                    Continuar
                                 </v-btn>
                              </v-flex>
                           </v-layout>
                        </v-stepper-content>

                        <!-- PASO 3 -->
                        <v-stepper-content
                           step="3"
                           :style="
                              $vuetify.breakpoint.xsOnly
                                 ? 'margin: 0px 0px; padding: 0px 0px;'
                                 : ''
                           "
                        >
                           <FormBecasDatosGenerales
                              :carnet-prop="carnet"
                              :beneficiario-id="selectBeneficiario"
                              :solicitud-info-prop="solicitudInfo"
                              :detalle-requisitos="detalleRequisitos"
                              :bienestar-universitario-prop="
                                 bienestarUniversitario
                              "
                              preinscripcion
                              @cancelGoStep="stepNum = $event"
                              @nextStep="nextStepDatosGenerales($event)"
                           />
                        </v-stepper-content>
                     </v-stepper-items>
                  </v-stepper>
               </v-flex>
            </v-layout>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
import FormBecasDatosGenerales from './FormularioBecasDatosGenerales'
import ReaplicacionBecaAdjunto from './ReaplicacionBecaAdjunto'

export default {
   name: 'PreinscripcionBeca',
   directives: {
      mask
   },
   components: {
      FormBecasDatosGenerales,
      ReaplicacionBecaAdjunto
   },
   mixins: [formatters],
   data: (vm) => ({
      mensajeBecas: '',
      mostrarMensaje: false,
      snackColor: 'primary',
      snackText: '',
      snackbar: false,
      stepNum: 1,
      correoAdm: process.env.VUE_APP_CORREO_ADM,
      fechaSolicitud: vm.toISOLocal(new Date()).substr(0, 10),
      acepto: false,
      checkContinuar: false,

      // Variables de formulario
      tipoBeneficioId: 0,
      cmbBeneficiarios: [],
      validBeneficiario: true,
      selectBeneficiario: null,
      carnet: '',
      validCarnet: true,
      verificandoRequisitos: false,
      pasoRequisitos: null,
      solicitudBecaId: null,
      solicitudInfo: null,
      referencia: '',
      validReferencia: true,
      bienestarUniversitario: false,
      detalleRequisitos: {
         reprobadas: 0,
         irregular: 0,
         egresado: 0
      },

      // Reglas de validacion
      rulesCarnet: [
         (v) => !!v || 'Ingresa tu carnet',
         (v) => (v && v.length == 11) || 'Termina de escribir tu carnet'
      ],
      rulesBeneficiario: [(v) => !!v || 'Seleccione un tipo de beneficiario'],
      rulesReferencia: [(v) => !!v || 'Ingrese una referencia']
   }),
   computed: {
      ...authComputed,
      selectBeneficiarioNombre() {
         if (this.selectBeneficiario != null) {
            return this.cmbBeneficiarios.find(
               (obj) => obj.beneficiarioId == this.selectBeneficiario
            ).nombre
         } else {
            return ''
         }
      },
      filteredBeneficiarios() {
         return this.cmbBeneficiarios.filter((i) => {
            return i.tipoBeneficioId == this.tipoBeneficioId && i.activo == true
         })
      }
   },
   watch: {
      carnet() {
         if (this.stepNum == 2.1 && this.pasoRequisitos != null) {
            this.pasoRequisitos = null
            this.checkContinuar = false
         }
      }
   },
   created() {
      // Validando acceso al componente
      if (this.permController(10, 'ver')) {
         this.listarComboBoxes()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      generarMensajeRequisitos() {
         var mensaje = ''
         if (this.detalleRequisitos.reprobadas > 0) {
            mensaje = mensaje.concat(
               'El alumno no cumple con los requisitos para aplicar a una beca.'
            )
         }
         if (this.detalleRequisitos.irregular > 0) {
            mensaje = mensaje.concat(
               '\n\nSe han detectado irregularidades en el historial académico del alumno.'
            )
         }
         if (this.detalleRequisitos.egresado > 0) {
            mensaje = mensaje.concat(
               '\n\nEl alumno se encuentra en calidad de egresado.'
            )
         }
         mensaje = mensaje.concat('\n\n¿Desea continuar de todas formas?')
         return mensaje.trim()
      },
      revisarSolicitud() {
         if (this.$refs.formReferencia.validate()) {
            axios
               .get('api/SolicitudDeBeca?numeroRef=' + this.referencia)
               .then((response) => {
                  if (response.data.length > 0) {
                     this.solicitudInfo = response.data[0]
                     this.solicitudBecaId = response.data.solicitudBecaId
                     this.stepNum = 3
                  } else {
                     this.showSnack(
                        'Número de referencia incorrecto',
                        'snackError'
                     )
                  }
               })
         }
      },
      nextStepDatosGenerales(soliInfo) {
         this.solicitudBecaId = soliInfo.solicitudBecaId
         this.selectBeneficiario = soliInfo.beneficiarioId
         this.solicitudInfo = soliInfo

         // Si ya esta cargada una solicitud, solo pasar al siguiente step
         if (
            this.selectBeneficiario == 1 ||
            this.selectBeneficiario == 2 ||
            this.selectBeneficiario == 8 ||
            this.selectBeneficiario == 9
         ) {
            this.stepNum = 3.1
         } else {
            this.stepNum = 4
         }
      },
      nextStepRequisitos() {
         this.stepNum = 3
      },
      validarRequisitos() {
         if (this.$refs.formCarnet.validate()) {
            this.pasoRequisitos = null
            this.detalleRequisitos = {
               reprobadas: 0,
               irregular: 0,
               egresado: 0
            }
            this.checkContinuar = false
            this.verificandoRequisitos = true
            setTimeout(() => {
               axios
                  .get('api/SolicitudDeBeca/RequisitosBecario/' + this.carnet)
                  .then((response) => {
                     this.detalleRequisitos = response.data
                     if (
                        this.detalleRequisitos.reprobadas == 0 &&
                        this.detalleRequisitos.irregular == 0 &&
                        this.detalleRequisitos.egresado == 0
                     ) {
                        this.pasoRequisitos = true
                     } else {
                        this.pasoRequisitos = false
                     }
                  })
                  .catch(() => {
                     this.pasoRequisitos = false
                  })
                  .finally(() => {
                     this.verificandoRequisitos = false
                  })
            }, 1500)
         }
      },
      cancelarValidarRequisitos() {
         this.$refs.formCarnet.resetValidation()
         this.pasoRequisitos = null
         this.carnet = ''
         this.verificandoRequisitos = false
         this.stepNum = 2
      },
      nextStepBeneficiarios() {
         if (this.$refs.formBeneficiario.validate()) {
            if (
               this.selectBeneficiario == 1 ||
               this.selectBeneficiario == 2 ||
               this.selectBeneficiario == 8 ||
               this.selectBeneficiario == 9
            ) {
               this.stepNum = 2.1
            } else {
               this.stepNum = 3
            }
         }
      },
      cancelarBeneficiarios() {
         this.$refs.formBeneficiario.resetValidation()
         this.selectBeneficiario = null
         this.stepNum = 1
      },
      chooseServEstudiantiles() {
         this.tipoBeneficioId = 2
         this.stepNum = 2
      },
      // NUevo Beneficio
      chooseAuspiciada() {
         this.tipoBeneficioId = 3
         this.stepNum = 2
      },
      chooseAdministrativa() {
         this.tipoBeneficioId = 1
         this.stepNum = 2
      },
      // Muestra snack
      showSnack(text, color) {
         this.snackText = text
         this.snackColor = color
         this.snackbar = true
      },
      checkRangoAlto() {
         if (this.oidcUser) {
            return Number(this.oidcUser.nivelasesor) > 1 ||
               this.oidcUser.role == 'admin'
               ? true
               : false
         } else {
            return false
         }
      },
      listarComboBoxes() {
         axios
            .get('api/solicitudesdebecas/Beneficiario')
            .then((response) => {
               this.cmbBeneficiarios = response.data
            })
            .catch((error) => {
               console.log(error.response.data)
            })
      },
      sacameDeAqui() {
         window.location = process.env.VUE_APP_OUTURL
      }
   }
}
</script>

<style scoped>
.tiltImage {
   transition: transform 0.2s ease-in-out;
}
.tiltImage:hover {
   transform: rotateZ(-5deg);
}
.primaryColor {
   color: var(--v-primary-base) !important;
}
</style>
