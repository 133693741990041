<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <v-dialog v-model="dialogEnviandoComp" persistent width="300">
            <v-card color="primary" dark class="pt-5 pb-2">
               <v-card-text style="color: white">
                  Enviando becarios externos
                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>

         <v-dialog v-model="dialogAdjunto" persistent max-width="600px">
            <v-form ref="formAdjunto" v-model="validAdjunto" lazy-validation>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Adjuntar listado</span
                              >
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12>
                              <v-file-input
                                 v-model="archivosAdjuntos"
                                 color="primary"
                                 :rules="adjuntosRules"
                                 label="Archivo adjunto"
                                 placeholder="Selecciona tu archivo"
                                 prepend-icon="fa-paperclip"
                                 accept=".csv"
                                 outlined
                                 multiple
                                 counter
                                 :show-size="1000"
                              >
                                 <template #selection="{ text }">
                                    <v-chip color="primary" dark label small>
                                       {{ text }}
                                    </v-chip>
                                 </template>
                              </v-file-input>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validAdjunto"
                        @click.native="saveAdjunto"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>
         <div class="text-center">
            <h2>Adjuntar listado de alumnos para renovación de beca</h2>
            <v-btn
               class="mx-2 mt-10"
               fab
               dark
               large
               color="primary"
               @click="dialogAdjunto = true"
            >
               <v-icon dark>fas fa-book</v-icon>
            </v-btn>
         </div>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'Becas',
   directives: {
      mask
   },
   data: () => ({
      // Variables
      validAdjunto: true,
      dialogEnviandoComp: false,
      editedIndex: -1,
      archivosAdjuntos: [],
      // Variables de formularios
      dialogBecaComp: false,
      dialogAdjunto: false,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      adjuntosRules: [
         (files) => files.length > 0 || 'Seleccione un archivo.',
         (files) => files.length <= 1 || 'Solo se puede subir un archivo.',
         (files) =>
            !files ||
            !files.some((file) => file.type != 'text/csv') ||
            'Solo se permiten archivos csv',
         (files) =>
            !files ||
            !files.some((file) => file.size > 15000000) ||
            'Los archivos adjuntos no pueden pesar mas de 15MB!'
      ]
   }),
   computed: {
      ...authComputed
   },
   watch: {},
   created() {},
   methods: {
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogBeca = false
         this.dialogAdjunto = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formAdjunto.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.archivosAdjuntos = []
      },
      saveAdjunto() {
         if (this.$refs.formAdjunto.validate()) {
            // Codigo para guardar
            this.dialogEnviandoComp = true
            let formData = new FormData()
            for (let file of this.archivosAdjuntos) {
               formData.append('file', file, file.name)
            }
            axios
               .post('api/SolicitudDeBeca/migracion', formData)
               .then(() => {
                  this.close()
                  this.snackText = 'Becarios agregados exitosamente!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  this.dialogEnviandoComp = false
                  this.listarBecas()
               })
               .catch((error) => {
                  console.log(error)
                  this.snackText = error.response.data.detail
                  this.snackColor = 'snackError'
                  this.snackbar = true
                  this.close()
                  this.dialogEnviandoComp = false
               })
         }
      }
   }
}
</script>
